/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Inject } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import * as moment from "moment";

import { MessagingService } from "@otimo/messaging";
import { LangService, RouteDataService, SharedReportsService, ReportPageBase } from "@otimo/report-base";
import { XmlReportDataService } from "@otimo/highcharts-xmlreport";
import { ParamData, ParametersDataService } from "@otimo/highcharts-parameters";

import { APP_SETTINGS, AppSettings } from "app/app.settings";
import { PortalChartConfig } from "&models/portalChartConfig";
import { GlobalReportConfig } from "&models/reportConfig";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "app-report-mini",
    templateUrl: "./report-mini.component.html"
})
export class ReportMiniComponent extends ReportPageBase implements OnInit, OnDestroy {
    //Common strings
    savedFilterQueryName = "filter";

    //Report settings
    public config: BehaviorSubject<PortalChartConfig> = new BehaviorSubject<PortalChartConfig>(null);

    //Params
    startdatParam = new ParamData("Startdatum", "startdat", null);
    stopdatParam = new ParamData("Slutdatum", "stopdat", null);
    alderParam = new ParamData("Åldersgrupper", "aldersgrupper", []);
    avlidenplatsParam = new ParamData("Avlidenplats", "avlidenplats", []);
    diagCancerParamData = new ParamData("Cancer", "diagCancer", []);
    diagHjartsjukdomParamData = new ParamData("Hjärtsjukdom", "diagHjartsjukdom", []);
    diagLungsjukdomParamData = new ParamData("Lungsjukdom", "diagLungsjukdom", []);
    diagDemensParamData = new ParamData("Kognitiv sjukdom", "diagDemens", []);
    diagStrokeParamData = new ParamData("Stroke", "diagStroke", []);
    diagAnnanNeurologisksjukdomParamData = new ParamData("Annan neurologisk sjukdom", "diagAnnanNeurologisksjukdom", []);
    diagInfektionParamData = new ParamData("Infektion", "diagInfektion", []);
    diagDiabetesParamData = new ParamData("Diabetes", "diagDiabetes", []);
    diagFrakturParamData = new ParamData("Fraktur", "diagFraktur", []);
    diagMultipelParamData = new ParamData("Multipel", "diagMultipel", []);
    diagOvrigtParamData = new ParamData("Annan", "diagOvrigt", []);
    dodsfallVantatParam = new ParamData("Förväntat dödsfall", "dodsfallVantat", []);
    enhetParam = new ParamData("Enhet", "enhet", []);
    kommunParam = new ParamData("Län/kommun", "kommun", []);
    konParam = new ParamData("Kön", "kon", []);

    constructor(
        @Inject(APP_SETTINGS) private env: AppSettings,
        protected location: Location,
        protected route: ActivatedRoute,
        protected router: Router,
        protected xmlReportService: XmlReportDataService,
        protected sharedReportsService: SharedReportsService,
        protected paramService: ParametersDataService,
        protected messaging: MessagingService,
        protected routeDataService: RouteDataService,
        protected langService: LangService,
        protected translate: TranslateService
    ) {
        super(location, route, router, xmlReportService, sharedReportsService, messaging, routeDataService, langService, translate);

        this.route.paramMap.subscribe(async (params) => {
            this.reportId = params.get("id"); //Rapportnamn

            this.loadReportConfig();
            //this.loadParamSettings();
            this.loadDrillDown();

            this.loadConfig(); // Måste köras sist när allt annat är laddat
        });
    }

    ngOnInit(): void {
        this.langSub = LangService.currentLang.subscribe((currentLang) => {
            if (!currentLang)
                return;

            this.language.next(currentLang);

            if (this.transSub) {
                this.transSub.unsubscribe();
            }
            //Invänta att translate-component ändrat språk och texter har lästs in
            this.transSub = this.translate.getTranslation(currentLang)
                .subscribe((obj) => {
                    this.transSub = null;
                    this.langHasInit.next(true);
                    this.setLanguageInConfig();
                });
        });
    }

    ngOnDestroy() {
        if (this.langSub) {
            this.langSub.unsubscribe();
        }
        if (this.transSub) {
            this.transSub.unsubscribe();
        }
    }

    /* Laddar report-config */
    protected loadReportConfig(): void {
        this.reportConfig = GlobalReportConfig.get(this.reportId);
        if (!this.reportConfig) {
            this.translate.get("general.invalid-report").subscribe((message: string) => {
                this.loadReportError(message);
                this.navigateTo("home");
            });
        }
    }

    /**
     * Innehåller logik för att bestämma vilka filter som skall användas i rapporten.
     */
    protected loadConfig() {
        // (Timeout på 750ms för att undvika att annat inte hunnit laddas innan vi skapar rapporten)
        setTimeout(() => {
            this.route.queryParamMap.subscribe((queryparams) => {
                if (queryparams.get(this.savedFilterQueryName)) {
                    //Har sparade filterinställningar i query-params?
                    this.loadFilterSettings(queryparams.get(this.savedFilterQueryName));
                } else {
                    this.setDefaultConfig(); //Sätt defaultfilter
                    this.setFiltersFromConfig();
                }
            });
        }, 750);
    }

    /**
     * Om det finns språk-inställningar, spara ner det i config
     */
    protected setLanguageInConfig(): void {
        const currentConfig = this.config.getValue();
        if (currentConfig) {
            const newConfig = new PortalChartConfig(this.env.baseHref);
            Object.assign(newConfig, currentConfig); //(ChangeDetection fungerar endast på objekt om hela objektet byts ut.)
            newConfig.language = LangService.currentLang.getValue();
            this.config.next(newConfig);
        }
    }

    /* Sätt config till dess default-värden */
    protected setDefaultConfig() {
        const defaultConfig = this.getDefaultConfig();
        this.config.next(defaultConfig);
        this.saveConfigToLocalStorage();
        this.configHasDefaultValues.next(true);
    }

    /* Hämta config med default-värden */
    protected getDefaultConfig(): PortalChartConfig {
        const defaultConfig = new PortalChartConfig(this.env.baseHref);
        defaultConfig.chartWidth = this.chartWidth;
        defaultConfig.language = this.language.getValue();
        defaultConfig.reportName = this.reportId;
        defaultConfig.grouping = "";
        this.setDefaultPeriod(defaultConfig);
        return defaultConfig;
    }

    private setDefaultPeriod(defaultConfig: PortalChartConfig) {
        const maxYear = this.reportConfig.paramSettings.maxYear;
        const minYear = this.reportConfig.paramSettings.minYear;
        const subtrMaxYearMonths = 0;
        defaultConfig.startdat = this.getPeriodStart(minYear, maxYear, subtrMaxYearMonths);
        defaultConfig.periodstart = [defaultConfig.startdat];
        defaultConfig.stopdat = this.getPeriodSlut(maxYear, subtrMaxYearMonths);
        defaultConfig.periodslut = [defaultConfig.stopdat];
        defaultConfig.currentYear = false;
    }

    private getPeriodStart(minYear, maxYear, subtrMaxYearMonths): string {
        const now = moment();
        const currentYear = now.format("YYYY");
        let periodStart = moment();
        if (minYear && maxYear)
            if (minYear == maxYear)
                periodStart = moment(minYear.toString() + "0101");
            else
                if (maxYear.toString() == currentYear)
                    periodStart = moment((maxYear - 1).toString() + now.format("MMDD"));
                else
                    periodStart = moment((maxYear - 1).toString() + "1231");
        else if (!minYear && maxYear)
            periodStart = moment((maxYear - 1).toString() + "1231");
        else if (minYear && !maxYear)
            if (minYear.toString() == currentYear)
                periodStart = moment(minYear.toString() + "0101");
            else
                periodStart = now.subtract(1, "years");
        else
            periodStart = now.subtract(1, "years");

        if (subtrMaxYearMonths)
            periodStart = periodStart.subtract(subtrMaxYearMonths, "months");

        return periodStart.format("YYYYMM");
    }

    private getPeriodSlut(maxYear, subtrMaxYearMonths): string {
        const now = moment();
        let periodSlut = moment();
        if (maxYear) {
            if (maxYear.toString() == now.format("YYYY"))
                periodSlut = moment(maxYear.toString() + now.format("MMDD"));
            else
                periodSlut = moment(maxYear.toString() + "1231");
        }
        else periodSlut = now;

        if (subtrMaxYearMonths)
            periodSlut = periodSlut.subtract(subtrMaxYearMonths, "months");

        return periodSlut.format("YYYYMM");
    }

    /**
     * Instantierar Utdataportalen-filter från config-rådata.
     */
    protected setFiltersFromConfig() {
        this.startdatParam = new ParamData(this.startdatParam.name, this.startdatParam.configId, this.config.getValue().startdat);
        this.stopdatParam = new ParamData(this.stopdatParam.name, this.stopdatParam.configId, this.config.getValue().stopdat);
        this.alderParam = new ParamData(this.alderParam.name, this.alderParam.configId, this.config.getValue().aldersgrupper);
        this.diagCancerParamData.data = this.config.getValue().diagCancer;
        this.diagHjartsjukdomParamData.data = this.config.getValue().diagHjartsjukdom;
        this.diagLungsjukdomParamData.data = this.config.getValue().diagLungsjukdom;
        this.diagDemensParamData.data = this.config.getValue().diagDemens;
        this.diagStrokeParamData.data = this.config.getValue().diagStroke;
        this.diagAnnanNeurologisksjukdomParamData.data = this.config.getValue().diagAnnanNeurologisksjukdom;
        this.diagInfektionParamData.data = this.config.getValue().diagInfektion;
        this.diagDiabetesParamData.data = this.config.getValue().diagDiabetes;
        this.diagFrakturParamData.data = this.config.getValue().diagFraktur;
        this.diagMultipelParamData.data = this.config.getValue().diagMultipel;
        this.diagOvrigtParamData.data = this.config.getValue().diagOvrigt;
        this.dodsfallVantatParam = new ParamData(this.dodsfallVantatParam.name, this.dodsfallVantatParam.configId, this.config.getValue().dodsfallVantat);
        this.enhetParam = new ParamData(this.enhetParam.name, this.enhetParam.configId, this.config.getValue().enhet);
        this.kommunParam = new ParamData(this.kommunParam.name, this.kommunParam.configId, this.config.getValue().kommun);
        this.konParam = new ParamData(this.konParam.name, this.konParam.configId, this.config.getValue().kon);
        this.avlidenplatsParam = new ParamData(this.avlidenplatsParam.name, this.avlidenplatsParam.configId, this.config.getValue().avlidenplats);
        this.params.next([this.startdatParam, this.stopdatParam, this.alderParam, this.enhetParam, this.konParam, this.avlidenplatsParam, this.kommunParam, this.dodsfallVantatParam,
                            this.diagCancerParamData, this.diagHjartsjukdomParamData, this.diagLungsjukdomParamData, this.diagDemensParamData, this.diagStrokeParamData,
                            this.diagAnnanNeurologisksjukdomParamData, this.diagInfektionParamData, this.diagDiabetesParamData, this.diagFrakturParamData, this.diagMultipelParamData, this.diagOvrigtParamData]);
    }


    /* Metoder som egentligen ska implementeras men som ej används i report-mini */
    protected getCommonFilters() { }
    public async clickedCategory(clickedCategory: string) { }
    protected setConfigFromDataType(a, b, c, d) { }
    public updateParam(x) { }
    public periodChange(x) { }
    public chartHeightChange(x) { }
    public saveUserReport() { }
    public changeGrouping() { }
    protected updateConfigAndTrigger() { }
    protected loadDrillDownParams() { }


}
