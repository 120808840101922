import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-signin",
    templateUrl: "./signin.component.html"
})
export class SigninComponent implements OnInit {

    ngOnInit() {
        return;
    }

}
