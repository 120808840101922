import { Component, Input } from "@angular/core";
import { ParamData, TreeViewSingleData } from "@otimo/highcharts-parameters";

@Component({
    selector: "parameter-list",
    templateUrl: "./parameter-list.component.html"
})
export class ParameterListComponent {

    @Input() textParamDatas: ParamData[];
    @Input() treeParamDatas: ParamData[];
    @Input() diagnosParamDatas: ParamData[];
    @Input() externKonsultParamDatas: ParamData[];

    @Input() treeViewDatas: TreeViewSingleData[];
    @Input() diagnosViewDatas: TreeViewSingleData[];
    @Input() externKonsultViewDatas: TreeViewSingleData[];

}
