import { AuthInterceptor, AuthModule, StsConfigLoader, StsConfigStaticLoader } from "angular-auth-oidc-client";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule, inject } from "@angular/core";
import { APP_SETTINGS } from "../../app.settings";



@NgModule({
    imports: [
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader, useFactory: (_) => {
                    const appSettings = inject(APP_SETTINGS);
                    return new StsConfigStaticLoader(appSettings.authConfig);
                }
            }
        })
    ],
    exports: [AuthModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ]
})

export class AuthConfigModule { }
