
<div id="print-box" hidden>
    <div id="chart" style="width:75%;height:400px">
        <xml-report-print [config]="config"
                          (passChartData)="receiveChartData($event)"></xml-report-print>
    </div>

    <div id="table" *ngIf="chartData">
        <table class="table table-sm table-bordered" *ngIf="chartData.detailedTable">
            <thead>
                <tr>
                    <th *ngFor="let column of chartData.detailedTable.columns">{{column}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of chartData.detailedTable.rows; let i = index">
                    <td *ngFor="let rowItem of row; let j = index"
                        class="text-{{chartData.detailedTable.alignments[i][j].toLowerCase()}} {{chartData.detailedTable.colors[i][j]}}">
                        {{rowItem}}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <th *ngFor="let column of chartData.detailedTable.columns">&ensp;</th>
                </tr>
            </tfoot>
        </table>
    </div>

    <div id="description" *ngIf="chartData">
        <table class="table table-sm content-table">
            <thead><tr><th></th></tr></thead>
            <tbody><tr><td><div [innerHTML]="chartData.descriptionHtml"></div></td></tr></tbody>
            <tfoot><tr><th></th></tr></tfoot>
        </table>
    </div>
</div>
