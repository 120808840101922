
<div id="report-mini">

    <xml-report (clickedCategory)="clickedCategory($event)"
                (loadReportError)="loadReportError($event)"
                [config]="config | async"
                [language]="language | async"
                [drillDownInfo]="drillDownInfo"
                [chartOnly]="true"></xml-report>

</div>