import { Component, Inject } from "@angular/core";
import { APP_SETTINGS, AppSettings } from "app/app.settings";


@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"]
})
export class FooterComponent {

    version = "";

    constructor(@Inject(APP_SETTINGS) private env: AppSettings) {
        this.version = `${env.info.product}\nVersion: ${env.info.version}\nBuild version: ${env.info.fileVersion}\n${env.info.copyright}`;
    }
}
