import { InjectionToken } from "@angular/core";
import { OpenIdConfiguration } from "angular-auth-oidc-client";

export class ApplicationInfo {
    product: string;
    version: string;
    fileVersion: string;
    copyright: string;
}
export class AppSettings {
    production: boolean;
    baseHref: string = "";
    authConfig: OpenIdConfiguration = {
        autoUserInfo: true,
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        disablePkce: false,
        ignoreNonceAfterRefresh: true
    };
    cookieAuthName: string = "";
    info: ApplicationInfo;

    getUserReportUrl(lang: string): string {
        return "/" + lang + "/myreports/";
    }
}

export const APP_SETTINGS = new InjectionToken<AppSettings>("environment");
