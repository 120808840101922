import { Component, ChangeDetectionStrategy, Input, Output, OnChanges, SimpleChanges, EventEmitter, ViewChild, ElementRef, Inject, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { ParamSetting } from "@otimo/report-base";
import { ParametersDataService, ParamData, PeriodData, TreeViewSingleData, ParametersConfig, ServerTreeViewData } from "@otimo/highcharts-parameters";
import { APP_SETTINGS, AppSettings } from "app/app.settings";
import { CookieService } from "ngx-cookie-service";

@Component({
    selector: "app-parameter-sidenav",
    changeDetection: ChangeDetectionStrategy.Default,
    templateUrl: "./parameter-sidenav.component.html",
    styleUrls: ["./parameter-sidenav.component.scss"]
})
export class ParameterSideNavComponent implements OnChanges, OnDestroy {

    constructor(@Inject(APP_SETTINGS) private env: AppSettings,
        private paramService: ParametersDataService,
        private modalService: NgbModal,
        private cookieService: CookieService,
        private translate: TranslateService,
        private viewportScroller: ViewportScroller,
        private cdref: ChangeDetectorRef
    ) {
        const cookie = cookieService.get(env.cookieAuthName);
        this.loggedIn = cookie.length > 0;
    }

    @Input() params: ParamData[];
    @Input() paramSettings: ParamSetting;
    @Input() saveFiltersForAllReports: boolean;
    @Input() canSaveUserReport: boolean;
    @Input() language: string;

    loggedIn = false;
    minYear = new BehaviorSubject<number>(undefined);
    maxYear = new BehaviorSubject<number>(undefined);
    startYears: number[] = [];
    startMonths = [];
    stopYears: number[] = [];
    stopMonths = [];

    @Output() updatedParam = new EventEmitter();
    @Output() periodChanged = new EventEmitter();
    @Output() clearedParams = new EventEmitter();
    @Output() sharedReport = new EventEmitter();
    @Output() savedUserReport = new EventEmitter();
    @Output() changedSaveForAllReports = new EventEmitter();

    textParamDatas = new BehaviorSubject<ParamData[]>([]);
    treeParamDatas = new BehaviorSubject<ParamData[]>([]);
    treeViewDatas = new BehaviorSubject<TreeViewSingleData[]>([]);
    diagnosParamDatas = new BehaviorSubject<ParamData[]>([]);
    diagnosViewDatas = new BehaviorSubject<TreeViewSingleData[]>([]);
    externKonsultParamDatas = new BehaviorSubject<ParamData[]>([]);
    externKonsultViewDatas = new BehaviorSubject<TreeViewSingleData[]>([]);
    vardAvParamDatas = new BehaviorSubject<ParamData[]>([]);
    vardAvViewDatas = new BehaviorSubject<TreeViewSingleData[]>([]);

    @ViewChild("urvalAccordion", { static: false }) urval: ElementRef;
    isCollapsed = true;

    // ParamData
    startdatData = new ParamData("Startdatum", "startdat", null);
    stopdatData = new ParamData("Slutdatum", "stopdat", null);
    startYearData = new ParamData();
    startMonthData = new ParamData();
    stopYearData = new ParamData();
    stopMonthData = new ParamData();
    alderParamData = new ParamData();
    dodsfallVantatParamData = new ParamData("Förväntat dödsfall");
    enhetParamData = new ParamData("Enhet");
    kommunParamData = new ParamData();
    konParamData = new ParamData();
    avlidenplatsParamData = new ParamData();
    covidParamData = new ParamData();
    hemtjanstParamData = new ParamData();
    //Diagnosgrupper-ParamData
    diagCancerParamData = new ParamData("Cancer");
    diagHjartsjukdomParamData = new ParamData("Hjärtsjukdom");
    diagLungsjukdomParamData = new ParamData("Lungsjukdom");
    diagDemensParamData = new ParamData("Kognitiv sjukdom");
    diagStrokeParamData = new ParamData("Stroke");
    diagAnnanNeurologisksjukdomParamData = new ParamData("Annan neurologisk sjukdom");
    diagMultipelParamData = new ParamData("Multipel");
    diagInfektionParamData = new ParamData("Infektion");
    diagOvrigtParamData = new ParamData("Annan");
    //Extern konsult-ParamData
    extAndligParamData = new ParamData("Andlig");
    extAnnanSjukhusenhetParamData = new ParamData("Annan sjukhusenhet");
    extPallTeamParamData = new ParamData("Palliativt team");
    extParamedicinareParamData = new ParamData("Paramedicinare");
    extSmartenhetParamData = new ParamData("Smärtenhet");
    extAnnanParamData = new ParamData("Annan");
    //Vård av-ParamData
    vardAvSPVParamData = new ParamData("SPV");
    vardAvAHSParamData = new ParamData("AHS");
    vardAvOkantParamData = new ParamData("Okänt");

    // ViewData
    alderViewData = new TreeViewSingleData("aldersgrupper", "Åldersgrupp");
    dodsfallVantatViewData = new TreeViewSingleData("dodsfallVantat", "Förväntat dödsfall");
    enhetViewData = new TreeViewSingleData("enhet", "Enhet");
    kommunViewData = new TreeViewSingleData("kommun", "Län/kommun");
    konViewData = new TreeViewSingleData("kon", "Kön", "", [], false);
    avlidenplatsViewData = new TreeViewSingleData("avlidenplats", "Avlidenplats");
    covidViewData = new TreeViewSingleData("covid", "Covid-19");
    hemtjanstViewData = new TreeViewSingleData("hemtjanst", "Hemtjänst", "", [], false);
    //Diagnosgrupper-ViewData
    diagCancerViewData = new TreeViewSingleData("diagCancer", "Cancersjukdom");
    diagHjartsjukdomViewData = new TreeViewSingleData("diagHjartsjukdom", "Hjärtsjukdom");
    diagLungsjukdomViewData = new TreeViewSingleData("diagLungsjukdom", "Lungsjukdom");
    diagDemensViewData = new TreeViewSingleData("diagDemens", "Kognitiv sjukdom");
    diagStrokeViewData = new TreeViewSingleData("diagStroke", "Stroke");
    diagAnnanNeurologisksjukdomViewData = new TreeViewSingleData("diagAnnanNeurologisksjukdom", "Annan neurologisk sjukdom");
    diagInfektionViewData = new TreeViewSingleData("diagInfektion", "Infektion");
    diagMultipelViewData = new TreeViewSingleData("diagMultipel", "Multisjuklighet");
    diagOvrigtViewData = new TreeViewSingleData("diagOvrigt", "Annan");
    //Extern konsult-ViewData
    extAndligViewData = new TreeViewSingleData("extAndlig", "Andlig");
    extAnnanSjukhusenhetViewData = new TreeViewSingleData("extAnnanSjukhusenhet", "Annan sjukhusenhet");
    extPallTeamViewData = new TreeViewSingleData("extPallTeam", "Palliativt team");
    extParamedicinareViewData = new TreeViewSingleData("extParamedicinare", "Paramedicinare");
    extSmartenhetViewData = new TreeViewSingleData("extSmartenhet", "Smärtenhet");
    extAnnanViewData = new TreeViewSingleData("extAnnan", "Annan");
    //Vård av-ViewData
    vardAvSPVViewData = new TreeViewSingleData("utfördSPV", "Specialiserad palliativ vård");
    vardAvAHSViewData = new TreeViewSingleData("utfördAHS", "Allmän hemsjukvård");
    vardAvOkantViewData = new TreeViewSingleData("utfördOkänt", "Okänt");



    private translationSub: Subscription;
    ngOnChanges(changes: SimpleChanges) {
        //Uppdatera urvalsalternativ om språk ändras
        if (changes["language"]) {
            const firstChange = changes["language"].firstChange;
            this.paramService.setAcceptCache(firstChange ? true : false);

            //Invänta att translate-component ändrat språk och texter har lästs in
            this.translationSub =
                this.translate.getTranslation(this.language)
                    .subscribe((obj) => {
                        this.loadBackendTreeviewData();
                    });
        }

        //Om params uppdateras behövs speciell hantering. Trigga underliggande komponenter manuellt.
        if (changes["params"]) {
            this.setParams();
            this.setParamDatas();
        }
        if (changes["paramSettings"]) {
            this.setMinMaxYear();
            this.loadYears();
        }
    }

    ngOnDestroy() {
        if (this.translationSub) {
            this.translationSub.unsubscribe();
        }
    }

    /**
     * Laddar alla data för TreeViews. Görs bara en gång.
     */
    private loadBackendTreeviewData(): void {
        this.loadYears();
        this.loadMonths();

        this.loadKon();
        this.loadAldersgrupp();
        this.loadCovid();
        this.loadKommuner();
        this.loadAvlidenplats();
        this.loadVardAv();
        this.loadHemtjanst();
        this.loadDodsfallVantat();
        this.loadDiagnoser();
        this.loadExternKonsult();

        this.paramService.setAcceptCache(false); //Hämta aldrig från cache när enhetsurval laddas nedan
        this.loadEnheter();

        this.treeViewDatas.next([this.konViewData, this.alderViewData, this.enhetViewData, this.avlidenplatsViewData, this.kommunViewData, this.dodsfallVantatViewData, this.covidViewData]);
        this.diagnosViewDatas.next([this.diagCancerViewData, this.diagHjartsjukdomViewData, this.diagLungsjukdomViewData, this.diagDemensViewData, this.diagStrokeViewData, this.diagAnnanNeurologisksjukdomViewData, this.diagInfektionViewData, this.diagMultipelViewData, this.diagOvrigtViewData]);
        this.externKonsultViewDatas.next([this.extAndligViewData, this.extAnnanSjukhusenhetViewData, this.extPallTeamViewData, this.extParamedicinareViewData, this.extSmartenhetViewData, this.extAnnanViewData]);
        this.vardAvViewDatas.next([this.vardAvSPVViewData, this.vardAvAHSViewData, this.vardAvOkantViewData]);
        this.setParamDatas();
    }

    private setParamDatas(): void {
        this.textParamDatas.next([this.startdatData, this.stopdatData]);
        this.treeParamDatas.next([this.konParamData, this.alderParamData, this.enhetParamData, this.avlidenplatsParamData, this.kommunParamData, this.dodsfallVantatParamData, this.covidParamData]);
        this.diagnosParamDatas.next([this.diagCancerParamData, this.diagHjartsjukdomParamData, this.diagLungsjukdomParamData, this.diagDemensParamData, this.diagStrokeParamData, this.diagAnnanNeurologisksjukdomParamData, this.diagInfektionParamData, this.diagMultipelParamData, this.diagOvrigtParamData]);
        this.externKonsultParamDatas.next([this.extAndligParamData, this.extAnnanSjukhusenhetParamData, this.extPallTeamParamData, this.extParamedicinareParamData, this.extSmartenhetParamData, this.extAnnanParamData]);
        this.vardAvParamDatas.next([this.vardAvSPVParamData, this.vardAvAHSParamData, this.vardAvOkantParamData]);
    }

    private setMinMaxYear(): void {
        this.minYear.next(this.paramSettings.minYear);
        this.maxYear.next(this.paramSettings.maxYear);
    }

    private loadYears(): void {
        const years = [];
        const minYear = this.minYear.getValue() > 0 ? this.minYear.getValue() : 2011;
        const maxYear = this.maxYear.getValue() > 0 ? this.maxYear.getValue() : (new Date()).getFullYear();
        for (let i = minYear; i <= maxYear; i++) {
            years.push(i);
        }
        this.startYears = years;
        this.stopYears = years;
    }

    private loadMonths(): void {
        const months = [
            { key: "01", value: this.translate.instant("months.01") },
            { key: "02", value: this.translate.instant("months.02") },
            { key: "03", value: this.translate.instant("months.03") },
            { key: "04", value: this.translate.instant("months.04") },
            { key: "05", value: this.translate.instant("months.05") },
            { key: "06", value: this.translate.instant("months.06") },
            { key: "07", value: this.translate.instant("months.07") },
            { key: "08", value: this.translate.instant("months.08") },
            { key: "09", value: this.translate.instant("months.09") },
            { key: "10", value: this.translate.instant("months.10") },
            { key: "11", value: this.translate.instant("months.11") },
            { key: "12", value: this.translate.instant("months.12") }
        ];
        this.startMonths = months;
        this.stopMonths = months;
    }

    private getParametersConfig(dataType: string, getTree = false): ParametersConfig {
        const config = new ParametersConfig();
        config.apiBaseUrl = "";
        config.serverUrl = `${this.env.baseHref}/api/params`.replace("//api","/api");
        config.data = dataType;
        config.gettree = getTree;
        config.format = "json";
        return config;
    }

    private loadKon() {
        this.konViewData.title = this.translate.instant("urval.gender.header");
        this.paramService.setServerSingleData(this.konViewData, [
            new ServerTreeViewData(this.translate.instant("urval.gender.options.women"), "K"),
            new ServerTreeViewData(this.translate.instant("urval.gender.options.men"), "M")
        ], this.konParamData);
    }

    private loadAldersgrupp() {
        this.alderViewData.title = this.translate.instant("urval.alder.header");
        this.alderViewData.chooseAllText = this.translate.instant("urval.alder.choose-all");
        const config = this.getParametersConfig("aldersgrupp", false);
        this.paramService.getParameterData(config).subscribe((data: any) => {
            this.paramService.setServerSingleData(this.alderViewData, data, this.alderParamData);
        });
    }

    private loadCovid() {
        this.covidViewData.title = this.translate.instant("urval.covid.header");
        this.covidViewData.chooseAllText = this.translate.instant("urval.covid.options.all");
        const genomgangenOption: ServerTreeViewData = {
            Key: "4",
            Value: this.translate.instant("urval.covid.options.genomgangen"),
            Children: [new ServerTreeViewData(this.translate.instant("urval.covid.options.genomgangen-max30"), "6"),
                new ServerTreeViewData(this.translate.instant("urval.covid.options.genomgangen-min30"), "7")]
        };
        this.paramService.setServerSingleData(this.covidViewData, [
            new ServerTreeViewData(this.translate.instant("urval.covid.options.pagaende"), "2"),
            new ServerTreeViewData(this.translate.instant("urval.covid.options.misstankt"), "3"),
            genomgangenOption
        ], this.covidParamData);
    }

    private loadEnheter() {
        this.enhetViewData.title = this.translate.instant("urval.enhet.header");
        this.enhetViewData.chooseAllText = this.translate.instant("urval.enhet.choose-all");
        const config = this.getParametersConfig("enhet", false);
        this.paramService.getParameterData(config).subscribe((data: any) => {
            if (data != null) {
                this.paramService.setServerSingleData(this.enhetViewData, data, this.enhetParamData);
            } else {
                this.enhetViewData.serverData = null; //Används för urvalet "Mina enheter"
            }
        });
    }

    private loadKommuner() {
        this.kommunViewData.title = this.translate.instant("urval.kommun.header");
        this.kommunViewData.chooseAllText = this.translate.instant("urval.kommun.choose-all");
        const config = this.getParametersConfig("lankommun", true);
        this.paramService.getParameterData(config).subscribe((data: any) => {
            this.paramService.setServerSingleData(this.kommunViewData, data, this.kommunParamData);
        });
    }

    private loadAvlidenplats() {
        this.avlidenplatsViewData.title = this.translate.instant("urval.avlidenplats.header");
        this.avlidenplatsViewData.chooseAllText = this.translate.instant("urval.avlidenplats.choose-all");
        const config = this.getParametersConfig("avlidenplats", false);
        this.paramService.getParameterData(config).subscribe((data: any) => {
            this.paramService.setServerSingleData(this.avlidenplatsViewData, data, this.avlidenplatsParamData);
        });
    }

    private loadVardAv() {
        this.paramService.setServerSingleData(this.vardAvSPVViewData, [new ServerTreeViewData(this.translate.instant("urval.vard-av.options.SPV"), "1")], this.vardAvSPVParamData);
        this.paramService.setServerSingleData(this.vardAvAHSViewData, [new ServerTreeViewData(this.translate.instant("urval.vard-av.options.AHS"), "1")], this.vardAvAHSParamData);
        this.paramService.setServerSingleData(this.vardAvOkantViewData, [new ServerTreeViewData(this.translate.instant("urval.vard-av.options.Okant"), "1")], this.vardAvOkantParamData);
    }

    private loadHemtjanst() {
        this.hemtjanstViewData.title = this.translate.instant("urval.hemtjanst.header");
        this.paramService.setServerSingleData(this.hemtjanstViewData, [
            new ServerTreeViewData(this.translate.instant("urval.hemtjanst.options.ja"), "1"),
            new ServerTreeViewData(this.translate.instant("urval.hemtjanst.options.nej"), "0"),
            new ServerTreeViewData(this.translate.instant("urval.hemtjanst.options.vet-ej"), "2")
        ], this.hemtjanstParamData);
    }

    private loadDiagnoser() {
        this.paramService.setServerSingleData(this.diagCancerViewData, [new ServerTreeViewData(this.translate.instant("urval.diagnosgrupp.options.cancer"), "1")], this.diagCancerParamData);
        this.paramService.setServerSingleData(this.diagHjartsjukdomViewData, [new ServerTreeViewData(this.translate.instant("urval.diagnosgrupp.options.hjartsjukdom"), "1")], this.diagHjartsjukdomParamData);
        this.paramService.setServerSingleData(this.diagLungsjukdomViewData, [new ServerTreeViewData(this.translate.instant("urval.diagnosgrupp.options.lungsjukdom"), "1")], this.diagLungsjukdomParamData);
        this.paramService.setServerSingleData(this.diagDemensViewData, [new ServerTreeViewData(this.translate.instant("urval.diagnosgrupp.options.demens"), "1")], this.diagDemensParamData);
        this.paramService.setServerSingleData(this.diagStrokeViewData, [new ServerTreeViewData(this.translate.instant("urval.diagnosgrupp.options.stroke"), "1")], this.diagStrokeParamData);
        this.paramService.setServerSingleData(this.diagAnnanNeurologisksjukdomViewData, [new ServerTreeViewData(this.translate.instant("urval.diagnosgrupp.options.annan-neurologisk"), "1")], this.diagAnnanNeurologisksjukdomParamData);
        this.paramService.setServerSingleData(this.diagInfektionViewData, [new ServerTreeViewData(this.translate.instant("urval.diagnosgrupp.options.infektion"), "1")], this.diagInfektionParamData);
        this.paramService.setServerSingleData(this.diagMultipelViewData, [new ServerTreeViewData(this.translate.instant("urval.diagnosgrupp.options.multipel"), "1")], this.diagMultipelParamData);
        this.paramService.setServerSingleData(this.diagOvrigtViewData, [new ServerTreeViewData(this.translate.instant("urval.diagnosgrupp.options.ovrigt"), "1")], this.diagOvrigtParamData);
    }

    private loadDodsfallVantat() {
        this.dodsfallVantatViewData.title = this.translate.instant("urval.dodsfall-vantat.header");
        this.dodsfallVantatViewData.chooseAllText = this.translate.instant("urval.dodsfall-vantat.options.all");
        this.paramService.setServerSingleData(this.dodsfallVantatViewData, [
            new ServerTreeViewData(this.translate.instant("urval.dodsfall-vantat.options.forvantat"), "1"),
            new ServerTreeViewData(this.translate.instant("urval.dodsfall-vantat.options.ovantat"), "2"),
            new ServerTreeViewData(this.translate.instant("urval.dodsfall-vantat.options.vetej"), "3")
            //new ServerTreeViewData(this.translate.instant('urval.dodsfall-vantat.options.ejbesvarat'), "255")
        ], this.dodsfallVantatParamData);
    }

    private loadExternKonsult() {
        this.paramService.setServerSingleData(this.extAndligViewData, [new ServerTreeViewData(this.translate.instant("urval.extern-konsult.options.andlig"), "1")], this.extAndligParamData);
        this.paramService.setServerSingleData(this.extAnnanSjukhusenhetViewData, [new ServerTreeViewData(this.translate.instant("urval.extern-konsult.options.annan-sjukhusenhet"), "1")], this.extAnnanSjukhusenhetParamData);
        this.paramService.setServerSingleData(this.extPallTeamViewData, [new ServerTreeViewData(this.translate.instant("urval.extern-konsult.options.palliativt-team"), "1")], this.extPallTeamParamData);
        this.paramService.setServerSingleData(this.extParamedicinareViewData, [new ServerTreeViewData(this.translate.instant("urval.extern-konsult.options.paramedicinare"), "1")], this.extParamedicinareParamData);
        this.paramService.setServerSingleData(this.extSmartenhetViewData, [new ServerTreeViewData(this.translate.instant("urval.extern-konsult.options.smartenhet"), "1")], this.extSmartenhetParamData);
        this.paramService.setServerSingleData(this.extAnnanViewData, [new ServerTreeViewData(this.translate.instant("urval.extern-konsult.options.Annan"), "1")], this.extAnnanParamData);
    }

    public periodSelectChange(): void {
        const start = parseInt(this.startYearData.data);
        const stop = parseInt(this.stopYearData.data);
        if (start > stop) {
            this.stopYearData.data = this.startYearData.data;
            this.cdref.detectChanges();
        }
        this.startdatData.data = this.startYearData.data + this.startMonthData.data;
        this.stopdatData.data = this.stopYearData.data + this.stopMonthData.data;
        this.periodChange( new PeriodData( this.startdatData, this.stopdatData ) );
    }

    /**
     * Matchar inkommande parametrar mot underliggande komponenter
     */
    private setParams() {
        this.params.forEach((param) => {
            switch (param.configId) {
                case "startdat":
                case "stopdat":
                    if (param.data) {
                        const year = param.data.toString().substring(0, 4);
                        const month = param.data.toString().substring(4, 6);
                        if (param.configId == "startdat") {
                            this.startYearData.data = year;
                            this.startMonthData.data = month;
                            this.startdatData.data = this.startYearData.data + this.startMonthData.data;
                        }
                        else if (param.configId == "stopdat") {
                            this.stopYearData.data = year;
                            this.stopMonthData.data = month;
                            this.stopdatData.data = this.stopYearData.data + this.stopMonthData.data;
                        }
                    }
                    break;
                case "aldersgrupper":
                    this.alderParamData = param;
                    break;
                case "avlidenplats":
                    this.avlidenplatsParamData = param;
                    break;
                case "covid":
                    this.covidParamData = param;
                    break;

                case "diagCancer": this.diagCancerParamData = param; break;
                case "diagHjartsjukdom": this.diagHjartsjukdomParamData = param; break;
                case "diagLungsjukdom": this.diagLungsjukdomParamData = param; break;
                case "diagDemens": this.diagDemensParamData = param; break;
                case "diagStroke": this.diagStrokeParamData = param; break;
                case "diagAnnanNeurologisksjukdom": this.diagAnnanNeurologisksjukdomParamData = param; break;
                case "diagInfektion": this.diagInfektionParamData = param; break;
                case "diagMultipel": this.diagMultipelParamData = param; break;
                case "diagOvrigt": this.diagOvrigtParamData = param; break;

                case "dodsfallVantat": this.dodsfallVantatParamData = param; break;
                case "enhet": this.enhetParamData = param; break;

                case "extAndlig": this.extAndligParamData = param; break;
                case "extAnnanSjukhusenhet": this.extAnnanSjukhusenhetParamData = param; break;
                case "extPallTeam": this.extPallTeamParamData = param; break;
                case "extParamedicinare": this.extParamedicinareParamData = param; break;
                case "extSmartenhet": this.extSmartenhetParamData = param; break;
                case "extAnnan": this.extAnnanParamData = param; break;

                case "hemtjanst":
                    this.hemtjanstParamData = param;
                    break;
                case "kommun":
                    this.kommunParamData = param;
                    break;
                case "kon":
                    this.konParamData = param;
                    break;

                case "vardAvSPV": this.vardAvSPVParamData = param; break;
                case "vardAvAHS": this.vardAvAHSParamData = param; break;
                case "vardAvOkant": this.vardAvOkantParamData = param; break;

                default:
            }
        });
    }

    public setDiagnosGrupp(checked: boolean, diagnos: string): void {
        let diagnosParam = new ParamData();
        switch (diagnos) {
            case "Cancer": diagnosParam = this.diagCancerParamData; break;
            case "Hjärtsjukdom": diagnosParam = this.diagHjartsjukdomParamData; break;
            case "Lungsjukdom": diagnosParam = this.diagLungsjukdomParamData; break;
            case "Kognitiv sjukdom": diagnosParam = this.diagDemensParamData; break;
            case "Stroke": diagnosParam = this.diagStrokeParamData; break;
            case "Annan neurologisk sjukdom": diagnosParam = this.diagAnnanNeurologisksjukdomParamData; break;
            case "Infektion": diagnosParam = this.diagInfektionParamData; break;
            case "Multipel": diagnosParam = this.diagMultipelParamData; break;
            case "Övrigt": diagnosParam = this.diagOvrigtParamData; break;
        }
        diagnosParam.data = checked ? [1] : [];
        this.updateParam(diagnosParam);
    }

    public setExternKonsultGrupp(checked: boolean, konsult: string): void {
        let extParam = new ParamData();
        switch (konsult) {
            case "Andlig": extParam = this.extAndligParamData; break;
            case "Annan sjukhusenhet": extParam = this.extAnnanSjukhusenhetParamData; break;
            case "Palliativt team": extParam = this.extPallTeamParamData; break;
            case "Paramedicinare": extParam = this.extParamedicinareParamData; break;
            case "Smärtenhet": extParam = this.extSmartenhetParamData; break;
            case "Annan": extParam = this.extAnnanParamData; break;
        }
        extParam.data = checked ? [1] : [];
        this.updateParam(extParam);
    }

    public setVardAv(checked: boolean, vardAv: string): void {
        let vardAvParam = new ParamData();
        switch (vardAv) {
            case "SPV": vardAvParam = this.vardAvSPVParamData; break;
            case "AHS": vardAvParam = this.vardAvAHSParamData; break;
            case "Okänt": vardAvParam = this.vardAvOkantParamData; break;
        }
        vardAvParam.data = checked ? [1] : [];
        this.updateParam(vardAvParam);
    }


    /**
     * När urval öppnas eller stängs
     */
    tabToggle(event: any): void {
        if (event.nextState == true) {
            document.getElementById(event.panelId + "-header").classList.add("active");
        } else {
            document.getElementById(event.panelId + "-header").classList.remove("active");
        }
        if (event.panelId === "period-tab") { //Special för period-urval pga långt
            if (event.nextState == true) {
                this.urval.nativeElement.style.minHeight = "200px";
            } else {
                this.urval.nativeElement.style.minHeight = "780px";
            }
        }
    }

    /**
     * Avgör om urvalet ska visas eller ej
     * @param param
     */
    showThisParam(param: string): boolean {
        const showParam = this.paramSettings.skipParams.indexOf(param) === -1 && this.paramSettings.skipParams.indexOf("all") === -1
        return showParam;
    }

    /**
     * Avgör om rapporten ej använder urval
     */
    skipsAllParams(): boolean {
        const skipAllParams = this.paramSettings.skipParams.indexOf("all") > -1;
        return skipAllParams;
    }

    /**
     * En ParamData har uppdaterats på en av de underliggande treeview-componenterna
     * @param paramData
     */
    updateParam(paramData: ParamData) {
        this.updatedParam.emit(paramData); //Skicka vidare uppåt.
    }

    /**
     * Perioddata har uppdaterats
     */
    periodChange(periodData: PeriodData) {
        this.periodChanged.emit(periodData); //Skicka vidare uppåt.
    }

    /**
     * Användaren vill skapa en delningslänk för aktuell rapport och filterinställningar. Skicka vidare.
     */
    shareReport() {
        this.sharedReport.emit();
    }

    /**
     * Användaren vill spara aktuell rapport och filterinställningari personliga sidor. Skicka vidare.
     */
    saveUserReport() {
        this.savedUserReport.emit();
    }

    /**
     * Användaren vill rensa aktuella filterinställningar. Skicka vidare.
     */
    cleanParameters() {
        this.clearedParams.emit();
    }

    /**
     * GUI. Visa/dölj filter
     */
    toggleParameters() {
        this.isCollapsed = !this.isCollapsed;

        if (!this.isCollapsed) {
            setTimeout(() => {
                this.viewportScroller.scrollToAnchor("urval-accordion");
            }, 100);
        }
    }


    // Modal
    closeResult: string;

    /**
     * Öppna modal och visa sammanfattning av filterinställningarna
     * @param content
     */
    open(content): void {
        this.modalService.open(content).result.then((result: string) => {
            this.closeResult = `Closed with: ${result}`; //Tryckte OK
        }, (reason: string) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`; //Avbröt
        });
    }

    /**
     * Hämta ut specifik anledning till att modalen avbröts.
     * @param reason
     */
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return "by pressing ESC";
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return "by clicking on a backdrop";
        } else {
            return `with: ${reason}`;
        }
    }

}
