
<app-header></app-header>

<app-main-menu></app-main-menu>

<div id="content">
    <div class="bg-light py-5">
        <div class="container">
            <h1>{{welcome}}</h1>
            <div class="row justify-content-center">
                <div class="col-lg-10 col-xl-11">
                    <p [innerHTML]="text1"></p>
                    <p [innerHTML]="text2"></p>
                    <p [innerHTML]="text3"></p>
                    <p [innerHTML]="text4"></p>
                    <p [innerHTML]="text5"></p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
