//Modules
import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { APP_BASE_HREF, Location, PlatformLocation } from "@angular/common";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LargeMenuComponentsModule } from "@otimo/large-menu";
import { MessagingComponentsModule } from "@otimo/messaging";
import { ReportBaseComponentsModule, ReportBaseServicesModule } from "@otimo/report-base";
import { XmlReportComponentsModule, XmlReportServicesModule } from "@otimo/highcharts-xmlreport";
import { HighChartsParametersServicesModule, HighChartsParametersComponentsModule } from "@otimo/highcharts-parameters";
import { AppRoutingModule } from "./app-routing.module";

//Translate (ngx-translate för Angular2 https://github.com/ngx-translate/core)
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

//Components
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { MainMenuComponent } from "./components/main-menu/main-menu.component";
import { ParameterSideNavComponent } from "./components/parameter-sidenav/parameter-sidenav.component";
import { ParameterListComponent } from "./components/parameter-list/parameter-list.component";
import { ReportPrintComponent } from "./components/report-print/report-print.component";

//Pages
import { HomeComponent } from "./pages/home/home.component";
import { ReportComponent } from "./pages/report/report.component";
import { ReportMiniComponent } from "./pages/report-mini/report-mini.component";

//Pipes
import { KeysPipe } from "./pipes/keys.pipe";
import { CapitalizePipe } from "./pipes/capitalize.pipe";

//Services
import { MessagingService } from "@otimo/messaging";
import { LangService, LoggingService, SharedReportsService, RouteDataService } from "@otimo/report-base";
import { AuthConfigModule } from "./core/auth/auth-config.module";
import { SigninComponent } from "./pages/signin/signin.component";
import { CookieInterceptor } from "./core/interceptor/http-cookie.interceptor";

export function getBaseHref(platformLocation: PlatformLocation): string {
    return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        MainMenuComponent,
        ParameterSideNavComponent,
        ParameterListComponent,
        ReportPrintComponent,
        HomeComponent,
        ReportComponent,
        SigninComponent,
        ReportMiniComponent,
        KeysPipe,
        CapitalizePipe
    ],
    imports: [
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        AuthConfigModule,
        LargeMenuComponentsModule,
        MessagingComponentsModule,
        ReportBaseComponentsModule,
        ReportBaseServicesModule,
        XmlReportComponentsModule,
        XmlReportServicesModule,
        HighChartsParametersServicesModule,
        HighChartsParametersComponentsModule,
        NgbModule,
        BrowserModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        {
            provide: APP_BASE_HREF,
            useFactory: getBaseHref,
            deps: [PlatformLocation]
        },
        { provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor, multi: true },
        MessagingService,
        LangService,
        Location,
        LoggingService,
        SharedReportsService,
        RouteDataService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    // (Använd timestamp för att komma förbi cachning av texter)
return new TranslateHttpLoader(http, "./assets/i18n/", ".json?cb=" + new Date().getTime());
}
