
<div id="main-menu">
    <div class="container text-center">

        <div class="header-menu-item">
            <a class="nav-link" routerLink="/{{lang | async}}/home" routerLinkActive=""><i class="white fas fa-lg fa-home"></i></a>
        </div>

        <div id="dodsfallsenkat-rapporter" class="header-menu-item menu-large text-start">
            <a class="nav-link" [class.menu-active]="showDodsfallMenu" (click)="toggleDodsfallMenu()">
                {{ 'menu.dodsfall.header' | translate }}
                <i class="chevron ms-2" [class.chevron-up]="showDodsfallMenu" [class.chevron-down]="!showDodsfallMenu"></i>
            </a>
            <largeMenu-twoLevel [showMenu]="showDodsfallMenu"
                                [menuGroups]="dodsfallMenuGroups"
                                [firstLevelMenuLinks]="dodsfallMenuLinks"
                                (goToLink)="goToLink($event)"
                                (menuWasHidden)="closeAllRapportMenus()"></largeMenu-twoLevel>
        </div>

        <div id="narstaendeenkat-rapporter" class="header-menu-item menu-large text-start">
            <a class="nav-link" [class.menu-active]="showNarstaendeMenu" (click)="toggleNarstaendeMenu()">
                {{ 'menu.narstaende.header' | translate }}
                <i class="chevron ms-2" [class.chevron-up]="showNarstaendeMenu" [class.chevron-down]="!showNarstaendeMenu"></i>
            </a>
            <largeMenu-twoLevel [showMenu]="showNarstaendeMenu"
                                [menuGroups]="narstaendeMenuGroups"
                                (goToLink)="goToLink($event)"
                                (menuWasHidden)="closeAllRapportMenus()"></largeMenu-twoLevel>
        </div>

        <div *ngIf="loggedIn" id="my-reports" class="header-menu-item menu-large text-start">
            <a class="nav-link" (click)="goToMyReports()">
                <i class="fas fa-user-circle" aria-hidden="true"></i>&ensp;{{'menu.sparade-rapporter'|translate}}
            </a>
        </div>

        <div id="print-btn" class="header-menu-item float-end">
            <button type="button" class="nav-link btn btn-light rounded-circle"
                    [disabled]="!printDataIsLoaded"
                    onclick="window.print(); return false;"
                    [ngbTooltip]="'menu.skriv-ut'|translate" placement="bottom">
                <i class="fas fa-lg fa-print"></i>
            </button>
        </div>

    </div>
</div>
