import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from "@angular/core";
import { PortalChartConfig } from "&models/portalChartConfig";
import { HighChartDataBase } from "@otimo/highcharts-xmlreport";

/**
 * Ger en snygg utskrift med en sida för respektive del
 * (Diagram / Detaljerad tabell / Förklaring / Urval)
 **/
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "report-print",
    templateUrl: "./report-print.component.html"
})
export class ReportPrintComponent {

    @Input() config: PortalChartConfig;
    @Output() printDataIsLoaded = new EventEmitter();
    public chartData: HighChartDataBase;

    public receiveChartData(chartData): void {
        this.chartData = chartData;
        this.printDataIsLoaded.emit(true);
    }
}
