import { Component, Inject } from "@angular/core";
import { Location } from "@angular/common";
import { LangService } from "@otimo/report-base";
import { APP_SETTINGS, AppSettings } from "app/app.settings";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"]
})
export class HeaderComponent {

    constructor(@Inject(APP_SETTINGS) private env: AppSettings,
        private location: Location,
        private lang: LangService
    ) { }

    public goToMyReports(): void {
        const currentLang = LangService.currentLang.getValue();
        window.location.href = this.env.getUserReportUrl(currentLang);
    }

    public changeLanguage(language: string) {
        let path = this.location.path();
        const lang = path.split("/")[1];
        if (lang.length == 2)
            path = path.substr(3);
        const modifiedPath = "/" + language + path;

        this.location.go(modifiedPath);
        this.lang.changeLanguage(language);
    }

}
