import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { ReportComponent } from "./pages/report/report.component";
import { ReportMiniComponent } from "./pages/report-mini/report-mini.component";
import { SigninComponent } from "./pages/signin/signin.component";

//TIPS: Om språkstöd införs på portalen, ta bort redirect till svenska routes nedan

export const routes: Routes = [
    { path: "", redirectTo: "sv/home", pathMatch: "full" },
    { path: "signin", component: SigninComponent },
    { path: ":lang/signin", component: SigninComponent },
    { path: "en/home", redirectTo: "sv/home" },
    { path: ":lang/home", component: HomeComponent },
    { path: "report/:id", redirectTo: "sv/report/:id" },
    { path: "en/report/:id", redirectTo: "sv/report/:id" },
    { path: ":lang/report/:id", component: ReportComponent },
    { path: "report-mini/:id", redirectTo: "sv/report-mini/:id" },
    { path: "en/report-mini/:id", redirectTo: "sv/report-mini/:id" },
    { path: ":lang/report-mini/:id", component: ReportMiniComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
