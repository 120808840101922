import { ReportConfig, ParamSetting } from "@otimo/report-base";

/**
 * Globalt dataobjekt för rapportinställningar. Används för Routing, Drilldowns och filterparametrar
 */
export const GlobalReportConfig = new Map<string, ReportConfig>();


// -- Rapporter: Dödsfallsenkät --

// Brytpunktssamtal
GlobalReportConfig.set("enskilda_brytpunktssamtal-narstaende", new ReportConfig("Brytpunktssamtal till närstående", new ParamSetting(2018)));
GlobalReportConfig.set("enskilda_brytpunktssamtal-patient", new ReportConfig("Brytpunktssamtal till patient", new ParamSetting(2018)));
GlobalReportConfig.set("kval_brytpunktssamtal", new ReportConfig("Brytpunktssamtal till patient (beräknad kvalitetsindikator)", new ParamSetting(2018)));

// Ordinerad injektion
GlobalReportConfig.set("enskilda_illamaende", new ReportConfig("Ord. inj illamående vid behov"));
GlobalReportConfig.set("enskilda_rosslighet", new ReportConfig("Ord. inj rosslig andning vid behov"));
GlobalReportConfig.set("enskilda_opioid", new ReportConfig("Ord. inj stark opioid vid smärtgenombrott"));
GlobalReportConfig.set("enskilda_angest", new ReportConfig("Ord. inj ångestdämpande vid behov"));

// Skattning
GlobalReportConfig.set("enskilda_smartskattning", new ReportConfig("Smärtskattning"));
GlobalReportConfig.set("enskilda_ovrig-symtomskattning", new ReportConfig("Övrig symtomskattning"));

// Symtom
GlobalReportConfig.set("symtom_andnod", new ReportConfig("Andnöd"));
GlobalReportConfig.set("symtom_forvirring", new ReportConfig("Förvirring"));
GlobalReportConfig.set("symtom_illamaende", new ReportConfig("Illamående"));
GlobalReportConfig.set("symtom_rosslighet", new ReportConfig("Rosslighet"));
GlobalReportConfig.set("symtom_smarta", new ReportConfig("Smärta"));
GlobalReportConfig.set("symtom_angest", new ReportConfig("Ångest"));

// Trycksår
GlobalReportConfig.set("enskilda_trycksar", new ReportConfig("Trycksår vid dödsfallet"));
GlobalReportConfig.set("enskilda_trycksar-ankomst", new ReportConfig("Trycksår vid ankomst"));

//GlobalReportConfig.set("enskilda_trycksar-fordelning", new ReportConfig("Fördelning mellan trycksår vid dödsfallet"));

// Övrigt
GlobalReportConfig.set("enskilda_antal-dodsfall", new ReportConfig("Antal dödsfall"));
GlobalReportConfig.set("enskilda_behandlingsbeslut", new ReportConfig("Behandlingsbeslut"));
GlobalReportConfig.set("enskilda_dropp", new ReportConfig("Dropp/sondtillförsel sista levnadsdygnet"));
GlobalReportConfig.set("enskilda_munhalsobedomning", new ReportConfig("Munhälsobedömning"));
GlobalReportConfig.set("enskilda_erbjuda-eftersamtal", new ReportConfig("Efterlevandesamtal"));
GlobalReportConfig.set("enskilda_konsultation", new ReportConfig("Konsultation"));
GlobalReportConfig.set("enskilda_narvaro-dodsogonblick", new ReportConfig("Närvaro"));
GlobalReportConfig.set("kval_lilla", new ReportConfig("Spindeldiagram", new ParamSetting(2022)));
GlobalReportConfig.set("enskilda_senaste-lakarbesok", new ReportConfig("Undersökt av läkare"));
//GlobalReportConfig.set("enskilda_svar-smarta", new ReportConfig("Svår smärta"));
GlobalReportConfig.set("kval_tackningsgrad", new ReportConfig("Täckningsgrad", new ParamSetting(2011)));
GlobalReportConfig.set("enskilda_vantade-ovantade", new ReportConfig("Väntade/oväntade", new ParamSetting(2018)));
GlobalReportConfig.set("enskilda_individuell-vardplan-2024", new ReportConfig("Individuell vårdplan från 2024", new ParamSetting(2024)));
GlobalReportConfig.set("enskilda_individuell-vardplan", new ReportConfig("Individuell vårdplan", new ParamSetting(2022)));
GlobalReportConfig.set("enskilda_var-onskar-do", new ReportConfig("Var önskar personen dö", new ParamSetting(2022)));




// -- Rapporter: Närståendeenkät --

GlobalReportConfig.set("narstenkat_besvarade", new ReportConfig("Andel besvarade", new ParamSetting(2021)));
//GlobalReportConfig.set("narstenkat_enkater", new ReportConfig("Täckningsgrad", new ParamSetting(2021)));
//GlobalReportConfig.set("narstenkat_inlogg", new ReportConfig("Aktiveringsgrad", new ParamSetting(2021)));

// Frågor
// - Vård före dödsplats
GlobalReportConfig.set("narstenkat_fragor_fraga-2", new ReportConfig("Nöjd med vård", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_fragor_fraga-3", new ReportConfig("Nöjd med stöd", new ParamSetting(2021)));

// - Vård vid dödsplats
GlobalReportConfig.set("narstenkat_fragor_fraga-4", new ReportConfig("Insikt", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_fragor_fraga-5", new ReportConfig("Brytpunktssamtal närstående", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_fragor_fraga-6", new ReportConfig("Närståendepenning", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_fragor_fraga-7", new ReportConfig("Tillgänglighet akut", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_fragor_fraga-8", new ReportConfig("Tillgänglighet läkare", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_fragor_fraga-9", new ReportConfig("Nöjd med vård", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_fragor_fraga-10", new ReportConfig("Nöjd med stöd", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_fragor_fraga-12", new ReportConfig("Beslutsförmåga", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_fragor_fraga-16", new ReportConfig("Närvaro", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_fragor_fraga-17", new ReportConfig("Efterlevandesamtal", new ParamSetting(2021)));
// Symtom
//GlobalReportConfig.set("narstenkat_fragor_fraga-13", new ReportConfig("Smärta", new ParamSetting(2021)));
//GlobalReportConfig.set("narstenkat_fragor_fraga-13b", new ReportConfig("&emsp;&emsp;Lindrad", new ParamSetting(2021)));
//GlobalReportConfig.set("narstenkat_fragor_fraga-14", new ReportConfig("Ångest", new ParamSetting(2021)));
//GlobalReportConfig.set("narstenkat_fragor_fraga-14b", new ReportConfig("&emsp;&emsp;Lindrad", new ParamSetting(2021)));
//GlobalReportConfig.set("narstenkat_fragor_fraga-15", new ReportConfig("Förvirring", new ParamSetting(2021)));
//GlobalReportConfig.set("narstenkat_fragor_fraga-15b", new ReportConfig("&emsp;&emsp;Lindrad", new ParamSetting(2021)));

//Jämförelser
GlobalReportConfig.set("narstenkat_jamforelse_fraga-4", new ReportConfig("Brytpunktssamtal", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_jamforelse_fraga-5", new ReportConfig("Brytpunktssamtal närstående", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_jamforelse_fraga-12", new ReportConfig("Insikt", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_jamforelse_fraga-16", new ReportConfig("Närvaro", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_jamforelse_fraga-9", new ReportConfig("Nöjdhet", new ParamSetting(2021)));
GlobalReportConfig.set("narstenkat_jamforelse_fraga-17", new ReportConfig("Efterlevandesamtal", new ParamSetting(2021)));
