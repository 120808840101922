import { Component, OnInit, OnDestroy, Input, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { LangService } from "@otimo/report-base";
import { MenuGroup, MenuLink } from "@otimo/large-menu";
import { APP_SETTINGS, AppSettings } from "../../app.settings";
import { CookieService } from "ngx-cookie-service";

@Component({
    selector: "app-main-menu",
    templateUrl: "./main-menu.component.html",
    styleUrls: ["./main-menu.component.scss"]
})
export class MainMenuComponent implements OnInit, OnDestroy {

    @Input() printDataIsLoaded: boolean;

    dodsfallMenuLinks: MenuLink[] = [];
    dodsfallMenuGroups: MenuGroup[] = [];
    narstaendeMenuGroups: MenuGroup[] = [];

    loggedIn = false;

    constructor(@Inject(APP_SETTINGS) private env: AppSettings,
        private router: Router,
        private cookieService: CookieService,
        private translate: TranslateService
    ) {
        const cookie = cookieService.get(env.cookieAuthName);
        this.loggedIn = cookie.length > 0;
    }

    public lang = LangService.currentLang;

    private translateSub: Subscription;

    ngOnInit(): void {
        this.getMenuGroups();

        if (this.printDataIsLoaded == null)
            this.printDataIsLoaded = true;

        this.translateSub = this.translate.onLangChange.subscribe(() => {
            //Uppdatera rapportnamn vid språkbyte
            this.getMenuGroups();
        });
    }
    ngOnDestroy(): void {
        if (this.translateSub) {
            this.translateSub.unsubscribe();
        }
    }

    private getMenuGroups() {
        this.dodsfallMenuLinks = [
            new MenuLink("enskilda_antal-dodsfall", this.translate.instant("menu.dodsfall.rapporter.ovrigt.antal-dodsfall")),
            new MenuLink("enskilda_behandlingsbeslut", this.translate.instant("menu.dodsfall.rapporter.ovrigt.behandlingsbeslut")),
            new MenuLink("enskilda_dropp", this.translate.instant("menu.dodsfall.rapporter.ovrigt.dropp")),
            new MenuLink("enskilda_erbjuda-eftersamtal", this.translate.instant("menu.dodsfall.rapporter.ovrigt.erbjuda-eftersamtal")),
            new MenuLink("enskilda_konsultation", this.translate.instant("menu.dodsfall.rapporter.ovrigt.konsultation")),
            new MenuLink("enskilda_munhalsobedomning", this.translate.instant("menu.dodsfall.rapporter.ovrigt.munhalsobedomning")),
            new MenuLink("enskilda_narvaro-dodsogonblick", this.translate.instant("menu.dodsfall.rapporter.ovrigt.narvaro-dodsogonblick")),
            new MenuLink("kval_lilla", this.translate.instant("menu.dodsfall.rapporter.ovrigt.spindel")),
            //new MenuLink('enskilda_svar-smarta', this.translate.instant("menu.dodsfall.rapporter.ovrigt.svar-smarta")),
            new MenuLink("kval_tackningsgrad", this.translate.instant("menu.dodsfall.rapporter.ovrigt.tackningsgrad")),
            new MenuLink("enskilda_senaste-lakarbesok", this.translate.instant("menu.dodsfall.rapporter.ovrigt.senaste-lakarbesok")),
            new MenuLink("enskilda_vantade-ovantade", this.translate.instant("menu.dodsfall.rapporter.ovrigt.vantade-ovantade")),
            new MenuLink("enskilda_var-onskar-do", this.translate.instant("menu.dodsfall.rapporter.ovrigt.var-onskar-do")),
        ];
        this.dodsfallMenuGroups = [
            {
                groupId: "brytpunktssamtal", title: this.translate.instant("menu.dodsfall.rapporter.brytpunktssamtal.header"), linkChildren: [
                    new MenuLink("enskilda_brytpunktssamtal-patient", this.translate.instant("menu.dodsfall.rapporter.brytpunktssamtal.patient")),
                    new MenuLink("kval_brytpunktssamtal", this.translate.instant("menu.dodsfall.rapporter.brytpunktssamtal.beraknad")),
                    new MenuLink("enskilda_brytpunktssamtal-narstaende", this.translate.instant("menu.dodsfall.rapporter.brytpunktssamtal.narstaende"))
                ]
            },
            {
                groupId: "ord-injektion", title: this.translate.instant("menu.dodsfall.rapporter.ord-injektion.header"), linkChildren: [
                    new MenuLink("enskilda_illamaende", this.translate.instant("menu.dodsfall.rapporter.ord-injektion.illamaende")),
                    new MenuLink("enskilda_rosslighet", this.translate.instant("menu.dodsfall.rapporter.ord-injektion.rosslighet")),
                    new MenuLink("enskilda_opioid", this.translate.instant("menu.dodsfall.rapporter.ord-injektion.opioid")),
                    new MenuLink("enskilda_angest", this.translate.instant("menu.dodsfall.rapporter.ord-injektion.angest"))
                ]
            },
            {
                groupId: "skattning", title: this.translate.instant("menu.dodsfall.rapporter.skattning.header"), linkChildren: [
                    new MenuLink("enskilda_smartskattning", this.translate.instant("menu.dodsfall.rapporter.skattning.smartskattning")),
                    new MenuLink("enskilda_ovrig-symtomskattning", this.translate.instant("menu.dodsfall.rapporter.skattning.ovrig-symtomskattning"))
                ]
            },
            {
                groupId: "symtom", title: this.translate.instant("menu.dodsfall.rapporter.symtom.header"), linkChildren: [
                    new MenuLink("symtom_andnod", this.translate.instant("menu.dodsfall.rapporter.symtom.andnod")),
                    new MenuLink("symtom_forvirring", this.translate.instant("menu.dodsfall.rapporter.symtom.forvirring")),
                    new MenuLink("symtom_illamaende", this.translate.instant("menu.dodsfall.rapporter.symtom.illamaende")),
                    new MenuLink("symtom_rosslighet", this.translate.instant("menu.dodsfall.rapporter.symtom.rosslighet")),
                    new MenuLink("symtom_smarta", this.translate.instant("menu.dodsfall.rapporter.symtom.smarta")),
                    new MenuLink("symtom_angest", this.translate.instant("menu.dodsfall.rapporter.symtom.angest")),
                ]
            },
            {
                groupId: "Trycksår", title: this.translate.instant("menu.dodsfall.rapporter.trycksar.header"), linkChildren: [
                    new MenuLink("enskilda_trycksar-ankomst", this.translate.instant("menu.dodsfall.rapporter.trycksar.vid-ankomst")),
                    new MenuLink("enskilda_trycksar", this.translate.instant("menu.dodsfall.rapporter.trycksar.vid-dodsfall")),
                ]
            },
            {
                groupId: "vardplan", title: this.translate.instant("menu.dodsfall.rapporter.vardplan.header"), linkChildren: [
                    new MenuLink("enskilda_individuell-vardplan-2024", this.translate.instant("menu.dodsfall.rapporter.vardplan.individuell-vardplan-2024")),
                    new MenuLink("enskilda_individuell-vardplan", this.translate.instant("menu.dodsfall.rapporter.vardplan.individuell-vardplan")),
                ]
            },


            //{
            //    //groupId: 'trycksar', title: this.translate.instant('menu.dodsfall.rapporter.trycksar.header'), linkChildren: [
            //    //    //new MenuLink('enskilda_trycksar-fordelning', this.translate.instant("menu.dodsfall.rapporter.trycksar.fordelning"))
            //    //]
            //},
            /* PÅGÅENDE RAPPORTER (Denna grupp ska vara bortkommenterad vid skarp publicering) */
            //
            //{
            //    groupId: 'under-utv',
            //    title: "- Under utveckling -",
            //    linkChildren: [
            //    ]
            //}
            //
        ];
        this.narstaendeMenuGroups = [
            {
                groupId: "besvarade", title: this.translate.instant("menu.narstaende.rapporter.besvarade.header"), linkChildren: [
                    new MenuLink("narstenkat_besvarade", this.translate.instant("menu.narstaende.rapporter.besvarade.andel"), "", "Besvarade enkäter per utskickade enkäter"),

                    // OBS! Nedan rapporter är ännu dolda, kommentera bort vid skarp publicering
                    //new MenuLink('narstenkat_enkater', this.translate.instant("menu.narstaende.rapporter.besvarade.tackning"), "", "Besvarade enkäter per dödsfall"),
                    //new MenuLink('narstenkat_inlogg', this.translate.instant("menu.narstaende.rapporter.besvarade.aktivering"), "", "Skickade inloggningar per dödsfall")
                ]
            },
            {
                groupId: "vard-fore-dodsplats", title: this.translate.instant("menu.narstaende.rapporter.fragor.vard-fore"), linkChildren: [
                    new MenuLink("narstenkat_fragor_fraga-2", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-2")),
                    new MenuLink("narstenkat_fragor_fraga-3", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-3"))
                ]
            },
            {
                groupId: "vard-vid-dodsplats", title: this.translate.instant("menu.narstaende.rapporter.fragor.vard-vid"), linkChildren: [
                    new MenuLink("narstenkat_fragor_fraga-4", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-4")),
                    new MenuLink("narstenkat_fragor_fraga-5", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-5")),
                    new MenuLink("narstenkat_fragor_fraga-6", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-6")),
                    new MenuLink("narstenkat_fragor_fraga-7", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-7")),
                    new MenuLink("narstenkat_fragor_fraga-8", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-8")),
                    new MenuLink("narstenkat_fragor_fraga-9", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-9")),
                    new MenuLink("narstenkat_fragor_fraga-10", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-10")),
                    new MenuLink("narstenkat_fragor_fraga-12", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-12")),
                    new MenuLink("narstenkat_fragor_fraga-16", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-16")),
                    new MenuLink("narstenkat_fragor_fraga-17", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-17")),
                ]
            },
            {
                groupId: "jamforelser", title: this.translate.instant("menu.narstaende.rapporter.jamforelse.header"), linkChildren: [
                    new MenuLink("narstenkat_jamforelse_fraga-4", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-4-alt")),
                    new MenuLink("narstenkat_jamforelse_fraga-5", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-5")),
                    new MenuLink("narstenkat_jamforelse_fraga-12", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-12-alt")),
                    new MenuLink("narstenkat_jamforelse_fraga-16", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-16")),
                    new MenuLink("narstenkat_jamforelse_fraga-9", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-9-alt")),
                    new MenuLink("narstenkat_jamforelse_fraga-17", this.translate.instant("menu.narstaende.rapporter.fragor.fraga-17"))
                ]
            }

            //{
            //    groupId: 'symtom', title: "Symtom", linkChildren: [
            //        new MenuLink('narstenkat_fragor_fraga-13', this.translate.instant("menu.narstaende.rapporter.fragor.fraga-13")),
            //        new MenuLink('narstenkat_fragor_fraga-13b', this.translate.instant("menu.narstaende.rapporter.fragor.fraga-13b")),
            //        new MenuLink('narstenkat_fragor_fraga-14', this.translate.instant("menu.narstaende.rapporter.fragor.fraga-14")),
            //        new MenuLink('narstenkat_fragor_fraga-14b', this.translate.instant("menu.narstaende.rapporter.fragor.fraga-14b")),
            //        new MenuLink('narstenkat_fragor_fraga-15', this.translate.instant("menu.narstaende.rapporter.fragor.fraga-15")),
            //        new MenuLink('narstenkat_fragor_fraga-15b', this.translate.instant("menu.narstaende.rapporter.fragor.fraga-15b")),
            //    ]
            //}
        ];
    }

    public goToLink(menuLink: MenuLink): void {
        const linkUrl = "/" + this.lang.getValue() + menuLink.link;
        if (menuLink.filter.length > 0) {
            this.router.navigate([linkUrl], { queryParams: { filter: menuLink.filter } });
        } else {
            this.router.navigate([linkUrl]);
        }
    }

    public showDodsfallMenu = false;
    public showNarstaendeMenu = false;

    public toggleDodsfallMenu() {
        this.showDodsfallMenu = !this.showDodsfallMenu;
        this.showNarstaendeMenu = false;
    }

    public toggleNarstaendeMenu() {
        this.showDodsfallMenu = false;
        this.showNarstaendeMenu = !this.showNarstaendeMenu;
    }

    public closeAllRapportMenus() {
        this.showDodsfallMenu = false;
        this.showNarstaendeMenu = false;
    }


    public goToMyReports(): void {
        const currentLang = LangService.currentLang.getValue();
        window.location.href = this.env.getUserReportUrl(currentLang);
    }

}
