
<header id="header">
    <div class="container">
        <div class="row">
            <div id="logo-column" class="col-md-4">
                <div id="logo" class="text-center text-md-start py-2">
                    <a href="http://palliativregistret.se" target="_blank">
                        <img src="assets/images/palliativregistret_logo.svg" width="100" height="100" alt="" class="ml-2" />
                    </a>
                </div>
            </div>
            <div id="menu-column" class="col-md-8">
                <div class="text-center text-md-end mb-3 my-md-2 my-lg-3">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="my-2 mb-md-3">{{'header.portalname'|translate}}</h3>
                        </div>
                        <div class="col-12">
                            <ul id="header-menu" class="list-inline">
                                <li class="list-inline-item">
                                    <a href="http://palliativregistret.se">
                                        {{'header.back-to-website'|translate}}<i class="chevron chevron-sm chevron-right ml-2"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
