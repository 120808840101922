
<app-header></app-header>

<app-main-menu [printDataIsLoaded]="printDataIsLoaded | async"></app-main-menu>

<div id="content" class="mt-3 mt-md-4 pb-4 pb-md-5">

    <report-print *ngIf="mainChartIsLoaded | async" 
                  (printDataIsLoaded)="whenPrintDataIsLoaded($event)"
                  [config]="config | async"></report-print>

    <div class="container">
        <div id="wrapper">
            <div id="sideNav" *ngIf="langHasInit | async" [class.visible]="sideNavIsVisible">
                <a href="javascript:void(0)" class="closebtn" (click)="toggleSideNav()"></a>

                <app-parameter-sidenav [params]="params | async"
                                       [paramSettings]="paramSettings | async"
                                       [canSaveUserReport]="canSaveUserReport | async"
                                       [saveFiltersForAllReports]="saveFiltersForAllReports | async"
                                       [language]="language | async"
                                       (changedSaveForAllReports)="changeSaveForAllReports($event)"
                                       (clearedParams)="clearParams()"
                                       (sharedReport)="shareReport()"
                                       (savedUserReport)="saveUserReport()"
                                       (updatedParam)="updateParam($event)"
                                       (periodChanged)="periodChange($event)"></app-parameter-sidenav>
            </div>
            <div id="urvalBtn">
                <button type="button" class="btn btn-xs btn-secondary p-2" (click)="toggleSideNav()"
                        [ngbTooltip]="(sideNavIsVisible ? 'urval.tooltip-hide' : 'urval.tooltip-show') | translate"
                        placement="bottom" container="body">

                    <span [class.text-info]="!(configHasDefaultValues|async)">
                        <i class="fas fa-filter fa-lg"></i>
                    </span>

                    <div class="mt-2">
                        <i class="chevron" [class.chevron-left]="sideNavIsVisible == true" 
                                           [class.chevron-right]="sideNavIsVisible == false"></i>
                    </div>
                </button>
            </div>

            <div id="main" *ngIf="langHasInit | async"
                           [class.minus-params-width]="sideNavIsVisible"
                           [class.hide-when-params-is-visible]="phoneBrowserInUse && sideNavIsVisible">

                <report-grouping class="float-end" [groupings]="groupings | async"
                                 [currentGrouping]="currentGrouping | async"
                                 (changedGrouping)="changeGrouping($event)"></report-grouping>

                <ng-container *ngIf="showHighcharts | async">
                    <xml-report (exportChartData)="receiveChartData($event)"
                                (clickedCategory)="clickedCategory($event)"
                                (chartHeightChange)="chartHeightChange($event)"
                                (drillUp)="drilledUp()"
                                (loadReportError)="loadReportError($event)"
                                [config]="config | async"
                                [language]="language | async"
                                [currentChartHeight]="currentChartHeight"
                                [drillDownInfo]="drillDownInfo"
                                [elearningInfo]="elearningInfo"
                                ></xml-report>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
